import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage, Link } from 'gatsby-plugin-intl'

import Layout from '../../components/Layouts/layout'
import SEO from '../../components/seo'
import ComplaintsProcessNav from '../../components/SubPageNav/ComplaintsProcessNav'
import SubPageBottomNav from '../../components/SubPageBottomNav/SubPageBottomNav'

const FileComplaintPage = () => {
  const intl = useIntl()

  return (
    <Layout header={<FormattedMessage id='complaintsProcess.page02' />}>
      <SEO
        description={intl.formatMessage({
          id: 'metaTags.complaintsProcessDescription'
        })}
        lang={intl.locale}
        title={intl.formatMessage({ id: 'complaintsProcess.page02' })}
      />
      <Container>
        <Row>
          <Col lg={3} md={3}>
            <ComplaintsProcessNav activePath='/licensing-compliance/how-to-file-a-complaint/' />
          </Col>

          <Col>
            <Row align='center'>
              <Col>
                <h2>
                  <FormattedMessage id='complaintsProcess.header02' />
                </h2>
                <p>
                  <FormattedMessage id='howToFileComplaint.contentA' />{' '}
                  <Link to='/licensing-compliance/licensed-builder-complaint/'>
                    <FormattedMessage id='howToFileComplaint.contentAii' />
                  </Link>
                  . <FormattedMessage id='howToFileComplaint.contentAiii' />
                </p>
              </Col>
            </Row>

            <Row className='pageRow'>
              <Col>
                <h2>
                  <FormattedMessage id='complaintsProcess.header03' />
                </h2>
                <p>
                  <FormattedMessage id='howToFileComplaint.contentB' />{' '}
                  <Link to='/licensing-compliance/illegal-building-complaint/'>
                    <FormattedMessage id='howToFileComplaint.contentBii' />
                  </Link>
                  .
                  <br />
                  <FormattedMessage id='howToFileComplaint.contentBiii' />{' '}
                  <a href='https://obd.hcraontario.ca'>
                    <FormattedMessage id='howToFileComplaint.contentBiv' />
                  </a>
                  .
                </p>

                <p>
                  <FormattedMessage id='howToFileComplaint.contentC' />{' '}
                  <a href='mailto:investigations@hcraontario.ca'>
                    investigations@hcraontario.ca
                  </a>
                  .
                </p>
              </Col>
            </Row>

            <Row className='pageRow'>
              <Col>
                <h2>
                  <FormattedMessage id='complaintsProcess.header04' />
                </h2>
                <p>
                  <FormattedMessage id='complaintsProcess.content04E' />
                  <ul>
                    <li>
                      <FormattedMessage id='complaintsProcess.list04A' />{' '}
                      <b>
                        <FormattedMessage id='complaintsProcess.content04B' />
                      </b>
                      .
                    </li>

                    <li>
                      <FormattedMessage id='complaintsProcess.list04B' />
                    </li>

                    <li>
                      <FormattedMessage id='complaintsProcess.list04C' />
                    </li>

                    <li>
                      <FormattedMessage id='complaintsProcess.list04D' />
                    </li>

                    <li>
                      <FormattedMessage id='complaintsProcess.list04E' />
                    </li>

                    <li>
                      <FormattedMessage id='complaintsProcess.list04F' />
                    </li>

                    <li>
                      <FormattedMessage id='complaintsProcess.list04G' />
                    </li>
                  </ul>
                </p>
                <p>
                  <FormattedMessage id='complaintsProcess.content04F' />
                </p>

                <p>
                  <FormattedMessage id='complaintsProcess.content06A' />{' '}
                  <Link to='/licensing-compliance/complaints-process/'>
                    <FormattedMessage id='complaintsProcess.content06B' />
                  </Link>
                  .
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <SubPageBottomNav
              prevLink={{
                text: <FormattedMessage id='complaintsProcess.page01' />,
                path: '/licensing-compliance/complaints-process/'
              }}
              nextLink={{
                text: <FormattedMessage id='complaintsProcess.page03' />,
                path: '/licensing-compliance/possible-courses-of-action/'
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default FileComplaintPage
