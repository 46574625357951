import React, { useState } from 'react'
import { Visible } from 'react-grid-system'
import { Link, FormattedMessage } from 'gatsby-plugin-intl'
import componentStyles from './SubPageNav.module.scss'
import { FaPlus, FaMinus } from 'react-icons/fa'

const SubPageNav = ({ links, listStyle, ...props }) => {
  const [accordianOpen, toggleAccoridan] = useState(false)

  return (
    <>
      {listStyle === 'unordered' && (
        <div className={componentStyles.dropDown}>
          <p className={componentStyles.instructions}>
            <FormattedMessage id='nav.clickThroughTabs' />
          </p>

          <Visible sm xs>
            <button onClick={() => toggleAccoridan(!accordianOpen)}>
              {accordianOpen ? <FaMinus /> : <FaPlus />}
            </button>
          </Visible>
        </div>
      )}

      {(listStyle !== 'unordered' || !listStyle) && (
        <p className={componentStyles.instructions}>
          <FormattedMessage id='nav.clickThroughLinks' />
        </p>
      )}

      <nav className={componentStyles.subPageNav}>
        {listStyle === 'unordered' && (
          <ul
            role='presentation'
            className={
              accordianOpen ? componentStyles.open : componentStyles.closed
            }
          >
            {links.map((link, i) => (
              <li
                key={i}
                role='none'
                className={link.active && componentStyles.active}
                style={{ zIndex: `${links.length - i}` }}
              >
                <Link to={link.path} role='menuitem'>
                  <span className={componentStyles.linkText}>{link.text}</span>
                </Link>
              </li>
            ))}
          </ul>
        )}

        {(listStyle !== 'unordered' || !listStyle) && (
          <ol role='presentation'>
            {links.map((link, i) => (
              <li
                key={i}
                role='none'
                className={link.active && componentStyles.active}
                style={{ zIndex: `${links.length - i}` }}
              >
                <Link to={link.path} role='menuitem'>
                  <span className={componentStyles.linkText}>{link.text}</span>
                </Link>
              </li>
            ))}
          </ol>
        )}
      </nav>
    </>
  )
}

export default SubPageNav
