/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { useMediaQuery } from 'react-responsive'
import '../../styles/App.scss'

import Nav from '../Nav/Nav'
import NavMobile from '../Nav/NavMobile'
import PageHeader from '../PageHeader/PageHeader'
import PageFooter from '../PageFooter/PageFooter'

const Layout = ({
  header,
  subheader,
  breadcrumb,
  view,
  children,
  ...props
}) => {
  const isScreenSm = useMediaQuery({ maxWidth: 1200 })

  return (
    <>
      {isScreenSm ? <NavMobile view={view} /> : <Nav view={view} />}
      {header && (
        <PageHeader
          header={header}
          subheader={subheader}
          breadcrumb={breadcrumb}
        />
      )}
      <main className='pageBody'>{children}</main>
      <PageFooter />
    </>
  )
}

export default Layout
